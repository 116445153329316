// Generated by Framer (d894e1b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["gmtGzr02K", "vQZSWRTdo"];

const serializationHash = "framer-QBLuh"

const variantClassNames = {gmtGzr02K: "framer-v-umu5c", vQZSWRTdo: "framer-v-wdlltl"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 40, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Minus: "vQZSWRTdo", Plus: "gmtGzr02K"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "gmtGzr02K"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "gmtGzr02K", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-QBLuh", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-umu5c", className)} data-framer-name={"Plus"} layoutDependency={layoutDependency} layoutId={"gmtGzr02K"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({vQZSWRTdo: {"data-framer-name": "Minus"}}, baseVariant, gestureVariant)}><motion.div className={"framer-q63rw6"} data-framer-name={"Horizontal"} layoutDependency={layoutDependency} layoutId={"nUtKKU0lf"} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10}}/><motion.div className={"framer-1k4a6"} data-framer-name={"Vertical"} layoutDependency={layoutDependency} layoutId={"iA9wwdE65"} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, rotate: 0}} variants={{vQZSWRTdo: {rotate: -90}}}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-QBLuh [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-QBLuh .framer-1dxwgn7 { display: block; }", ".framer-QBLuh .framer-umu5c { height: 20px; overflow: hidden; position: relative; width: 20px; }", ".framer-QBLuh .framer-q63rw6 { flex: none; height: 1px; left: calc(50.00000000000002% - 16px / 2); overflow: hidden; position: absolute; top: calc(50.00000000000002% - 1px / 2); width: 16px; will-change: var(--framer-will-change-override, transform); }", ".framer-QBLuh .framer-1k4a6 { flex: none; height: 16px; left: calc(50.00000000000002% - 1px / 2); overflow: hidden; position: absolute; top: calc(50.00000000000002% - 16px / 2); width: 1px; will-change: var(--framer-will-change-override, transform); }", ".framer-QBLuh.framer-v-wdlltl .framer-umu5c { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 20px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 20
 * @framerIntrinsicWidth 20
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"vQZSWRTdo":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables false
 */
const FramerFRqZb5R6n: React.ComponentType<Props> = withCSS(Component, css, "framer-QBLuh") as typeof Component;
export default FramerFRqZb5R6n;

FramerFRqZb5R6n.displayName = "Icon";

FramerFRqZb5R6n.defaultProps = {height: 20, width: 20};

addPropertyControls(FramerFRqZb5R6n, {variant: {options: ["gmtGzr02K", "vQZSWRTdo"], optionTitles: ["Plus", "Minus"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerFRqZb5R6n, [])